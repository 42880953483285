.square-radio-box{
    display: block;
    width: calc(33.33% - 15px);
    @include sm{
        width: calc(50% - 7px);
        &:nth-child(odd){
            margin-right: 0;
        }
        &:nth-child(even){
            margin-right: 0;
            margin-left: auto;
        }
    }
    @include xs{
        width: 100%;
        span{
            text-align: left;
        }
    }
}


//
.mobile-target{
    display: none;
    width: 100%;
}
@include xs{
    .step-1-option-3-mobile{
        display: none !important;
    }
    .mobile-target{
        display: block;
    }
    .step-1-steps{
        padding-bottom: 40px;
    }
}
