.btn-1,
.btn-2{
    min-width: 200px;
    text-align: center;
    font-size: 14px;
    padding: 14px 15px;
    border-radius: $brr-3;
    letter-spacing: 1px;
    display: inline-block;
    transition: all 0.2s;
    border: none;
}
.btn-1{
    color: $white;
    background: $primary;
    @include boxshadow-1(rgba($secondary,0.15));
    &:hover{
        text-decoration: none;
        color: $secondary;
        @include boxshadow-2(rgba($secondary,0.25));
    }
}

.btn-2{
    background: $primary-2;
    color: $primary;
    &:hover{
        text-decoration: none;
        color: $secondary;
    }
}

.coupon-btn {
    width: 100%;
    min-width: inherit;
}