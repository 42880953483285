// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace ; }

// Alignment

.text-justify  { text-align: justify ; }
.text-wrap     { white-space: normal ; }
.text-nowrap   { white-space: nowrap ; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left ; }
    .text#{$infix}-right  { text-align: right ; }
    .text#{$infix}-center { text-align: center ; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase ; }
.text-uppercase  { text-transform: uppercase ; }
.text-capitalize { text-transform: capitalize ; }

// Weight and italics

.font-weight-light   { font-weight: $font-weight-light ; }
.font-weight-lighter { font-weight: $font-weight-lighter ; }
.font-weight-normal  { font-weight: $font-weight-normal ; }
.font-weight-bold    { font-weight: $font-weight-bold ; }
.font-weight-bolder  { font-weight: $font-weight-bolder ; }
.font-italic         { font-style: italic ; }

// Contextual colors

.text-white { color: $white ; }

@each $color, $value in $theme-text-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-body { color: $body-color ; }
.text-muted { color: $text-muted ; }

.text-black-50 { color: rgba($black, .5) ; }
.text-white-50 { color: rgba($white, .5) ; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}

.text-decoration-none { text-decoration: none ; }

.text-break {
  word-break: break-word ; // IE & < Edge 18
  overflow-wrap: break-word ;
}

// Reset

.text-reset { color: inherit ; }
