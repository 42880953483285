label{
    font-size: 16px;
    color: $secondary;
    letter-spacing: 1px;
    margin-bottom: 10px;
    font-weight: 400;
}

// Form control
.form-control{
    background: $primary-4;
    border-radius: $brr-3;
    display: block;
    width: 100%;
    border: 1px solid $primary;
    padding: 10px;
    color: $secondary;
    outline: none;
    min-height: 50px;
    &:focus{
        box-shadow: 0px 0px 0px 1px $primary;
    }
    &.error{
        box-shadow: 0px 0px 0px 1px $danger;
        background: rgba($danger,0.03);
        border-color: $danger;
    }

    // If it has calendar
    &.calendar{
        padding-right: 40px;
        background-image: url(../images/svg/calendar-icon.svg);
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: calc(100% - 10px)  center;
    }
}

.custom-select{
    position: relative;
    overflow: hidden;
    border-radius: $brr-2;
    border:1px solid $primary;
    &:after{
        pointer-events: none;
        content:'';
        height: 7px;
        width: 12px;
        right: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 451.85 257.57'%3E%3Cpath d='M225.92 257.57a31.57 31.57 0 01-22.37-9.27L9.27 54A31.64 31.64 0 0154 9.27l171.9 171.91L397.83 9.27A31.64 31.64 0 01442.57 54L248.29 248.31a31.56 31.56 0 01-22.37 9.26z' fill='%23202420'/%3E%3C/svg%3E");
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
    }
    select{
        width: 120%;
        height: 50px;
        border:none !important;
    }
}
// Reset radio
.reset-input{
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    input[type="checkbox"],
    input[type="radio"]{
        z-index: -99;
        height: 0;
        width: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
    }
}
// Radio group
.radio-group{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.square-radio-box{
    margin: 0 15px 15px 0;
    display: inline-block;
    span{
        border:2px solid $primary-2;
        border-radius: $brr-2;
        padding: 10px;
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
        text-align: center;
    }
    @extend .reset-input;
    input[type="radio"]{
        &:checked ~ span{
            border-color: $primary;
        }
    }
}
// Normal radio
.normal-radio{
    @extend .reset-input;
    span{
        padding-left: 25px;
        position: relative;
        display: block;
        &:before,
        &:after{
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            left: 0;
            top: 3px;
            border:2px solid $primary-2;
            content: '';
            border-radius: 50%;
        }
        &:before{
            border: none;
            width: 6px;
            height: 6px;
            left: 5px;
            top: 8px;
        }
    }
    input[type="radio"]{
        &:checked ~ span{
            &:before{
                background: $primary;
            }
            &:after{
                border-color: $primary;
            }
        }
    }
}

// Normal-checkbox
.normal-checkbox{
    @extend .reset-input;
    span{
        padding-left: 25px;
        position: relative;
        display: block;
        &:after{
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            left: 0;
            top: 3px;
            background-color: $primary-3;
            content: '';
            border-radius: $brr-4;
            background-repeat: no-repeat;
            background-size: 11px;
            background-position: center;
        }
    }
    input[type="checkbox"]{
        &:checked ~ span{
            &:after{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 376'%3E%3Cpath d='M504.5 7.5a25.59 25.59 0 00-36.2 0L161.59 314.21 43.7 196.31a25.6 25.6 0 00-36.2 36.21l136 136a25.61 25.61 0 0036.21 0L504.5 43.7a25.59 25.59 0 000-36.2z' fill='%2340af49' /%3E%3C/svg%3E");
            }
        }
    }
}


// Error design
.error-msg{
    label{
        color: $danger;
        font-size: 14px;
        padding-top: 5px;
        font-family: $mont;
        margin-bottom: 0;
    }
}