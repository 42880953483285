@import './plan-box';
@import './progressbar';


@import './step-1';
@import './step-2';
@import './step-3';
@import './step-4';

@import './success';

.navigator-buttons{
    @include xs{
        .btn-1,.btn-2{
            flex: 0 0 45%;
            max-width: 45%;
            min-width: inherit;
        }
    }
}