$primary    :   #40af49;
$secondary  :   #202420;
$danger     :   #f44336;

$primary-1  : #6b996f;
$primary-2  : #cfe6d0;
$primary-3  : #e6f5e7;
$primary-4  : #f5faf5;

$secondary-1  : #5b665b;

// Border radius
$brr-1    : 10px;
$brr-2    : 7px;
$brr-3    : 5px;
$brr-4    : 3px;

// Backgrounds

$background-colors : (
  'primary' : $primary,
  'secondary' : $secondary,
  'primary-1' : $primary-1,
  'primary-2' : $primary-2,
  'primary-3' : $primary-3,
  'primary-4' : $primary-4
);


// Font family
$raleway  : 'Raleway', sans-serif;
$mont     : 'Montserrat', sans-serif;

// Font sizes
$font-sizes: ();
$font-sizes: map-merge(
  (
    12: 12px,
    14: 14px,
    16: 16px,
    18: 18px,
    22: 22px,
    32: 32px
  ),
  $font-sizes
);



// Customizing bootstrap
// Removing theme colors
$theme-colors: map-remove($theme-colors, "primary","secondary","info", "light", "dark", "success","warning","danger");

$theme-colors: map-merge(
  (
    'primary'   : $primary,
    'secondary' : $secondary,
    'danger'    : $danger,
  ),
  $theme-colors
);


$theme-text-colors: (
  'primary'     : $primary,
  'secondary'   : $secondary,
  'secondary-1' : $secondary-1,
  'primary-1'   : $primary-1,
  'danger'      : $danger,
);

$theme-bg-colors: (
  'primary-1'     : $primary-1,
  'primary-2'     : $primary-2,
  'primary-3'     : $primary-3,
  'primary-4'     : $primary-4
);

// Spacing
$spacer: 5px;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 1),
    2: ($spacer * 2),
    3: ($spacer * 4),
    4: ($spacer * 6),
    5: ($spacer * 8)
  ),
  $spacers
);


$grid-columns:                4;
$grid-gutter-width:           20px;
