// Media query max
$screen-lg-max: 1199px;     // Tablets and small desktops
$screen-md-max: 991px;      // Small tablets (portrait view)
$screen-sm-max: 767px;      // Small tablets and large smartphones (landscape view)
$screen-xs-max: 575px;      // Mobile phones
// Desktop to mobile approach
    // Extra large devices
    @mixin lg {
        @media (max-width: #{$screen-lg-max}) {
            @content;
        }
    }

    // Large devices
    @mixin md {
        @media (max-width: #{$screen-md-max}) {
            @content;
        }
    }

    // Medium devices
    @mixin sm {
        @media (max-width: #{$screen-sm-max}) {
            @content;
        }
    }

    // Small devices
    @mixin xs {
        @media (max-width: #{$screen-xs-max}) {
            @content;
        }
    }