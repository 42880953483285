// Titles
h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
    margin-bottom: 0;
}

// Font families
.font-mont{
    font-family: $mont;
}
.font-raleway{
    font-family: $raleway;
}

// Font weight
.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}

// Letter spacing
.tracking-tighter{
    letter-spacing: -0.05em;
}
.tracking-tight{
    letter-spacing: -0.025em;
}
.tracking-normal{
    letter-spacing: 0;
}
.tracking-wide{
    letter-spacing: 0.025em;
}
.tracking-wider{
    letter-spacing: 0.05em;
}
.tracking-widest{
    letter-spacing: 0.1em;
}

// Line height
.leading-none{
    line-height: 1;
}
.leading-tight{
    line-height: 1.25;
}
.leading-snug{
    line-height: 1.375;
}
.leading-normal{
    line-height: 1.5;
}
.leading-relaxed{
    line-height: 1.625;
}
.leading-loose{
    line-height: 2;
}


// Font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $size, $font-sizes in $font-sizes {
            .fz#{$infix}-#{$size} { font-size: $font-sizes; }
        }
    }
}

