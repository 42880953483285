body{
    overflow-x: hidden;
    font-family: $raleway;
    @include sm{
        padding-bottom: 30px;
    }
}

// container
@media (min-width:300px){
    .container{
        max-width: 1366px;
    }
}