.plan-boxes{
    display: flex;
    align-items: stretch;
    padding-top: 30px;
    justify-content: center;
    .box{
        border-radius: $brr-3;
        overflow: hidden;
        border: 2px solid $primary-3;
        width: 100%;
        padding: 20px;
        max-width: 350px;
        &:before{
            margin: -20px -20px 20px -20px;
            display: block;
            height: 6px;
            background: $primary;
            content: '';
        }
    }

    .lite-plan{
        margin-top: 20px;
    }
    .pro-plan{
        border: none;
        margin-left: -10px;
        z-index: 2;
        @include boxshadow-1(rgba($secondary,0.15));
    }


    // Plan common
    .plan-title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        font-size: 14px;
        color: $secondary;
        font-family: $mont;
        text-align: center;
        margin-bottom: 20px;
    }
    .plan-price{
        margin-bottom: 30px;
        .price{
            color: $primary;
            font-weight: 300;
            font-size: 32px;
            display: flex;
            align-items: baseline;
            width: 100%;
            justify-content: center;
            line-height: 1;
            margin-bottom: 10px;
            span{
                font-size: 18px;
                font-weight: 600;
                color: $secondary;
            }
        }
        .info{
            text-align: center;
            color: $secondary;
            opacity: 0.7;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            display: block;
        }
    }
    .not-include,
    .plan-detail{
        list-style-type: none;
        padding: 0;
        margin: 0;
        > li{
            padding-left: 30px;
            background-image: url(../images/svg/check-icon.svg);
            background-repeat: no-repeat;
            background-position: left 5px;
            background-size: 13px;
            > ul{
                padding-left: 10px;
                > li{
                    list-style-type: disc;
                }
            }
        }
    }
    .not-include{
        list-style-type: none !important;
        margin: 0 ;
        padding: 0 !important;
        margin-left: -10px;
        > li{
            padding-left: 20px;
            list-style-type: none !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.976 51.976'%3E%3Cpath d='M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 01-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 11-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 112.828-2.828l7.425 7.425 7.071-7.071a2 2 0 112.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 010 2.828z' fill='red' /%3E%3C/svg%3E");
        }
    }
    .plan-price-box{
        font-weight: 600;
        padding-top: 128px;
    }
}

@include xs{
    .plan-boxes{
        flex-direction: column;
        .box{
            max-width: inherit;
            margin-bottom: 20px;
        }
        .lite-plan{
            margin-top: 0;
        }
        .pro-plan{
            margin-left: 0;
        }
        .plan-price-box{
            padding-top: 0;
        }
    }
}