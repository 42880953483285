.warning-box{
    position: relative;
    border-radius: $brr-2;
    padding-left: 60px !important;
    .warning-icon{
        position: absolute;
        top: 20px;
        left: 20px;
        width: 24px;
    }
    ul{
        padding-left: 17px;
        margin: 0;
    }
}

@include xs{
    .warning-box{
        padding-left: 30px !important;
        .warning-icon{
            position: absolute;
            top: 22px;
            left: 5px;
            width: 16px;
        }
        ul{
            padding: 0;
        }
    }
}