.main-container{
    position: relative;
    @include xs{
        padding: 0 30px;
    }
}
.sidebar{
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 500px;
}
.main{
    flex: 0 0 calc(100% - 500px);
    max-width: calc(100% - 500px);
    margin-left: auto;
    padding: 30px 50px;
    @include lg{
        padding: 30px;
    }
    @include md{
        padding: 0;
    }
}


// Responsive
@media (max-width:1366px){
    .sidebar{
        width: 33.33%;
    }
    .main{
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }
}

@include md{
    .sidebar{
        width: 100%;
        position: relative;
    }
    .main{
        flex: 0 0 100%;
        max-width: 100%;
    }
}