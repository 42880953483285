.sidebar{
    .main-logo{
        max-width: 140px;
        display: none;
    }
    &:before{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -100000px;
        z-index: 0;
        background-color: $primary-3;
        content: '';
    }
    .content{
        position: relative;
        z-index: 2;
        height: 100%;
        padding: 50px 0 0 0;
    }
    .intro-img{
        margin-top: auto;
    }
}

@include lg{
    .sidebar{
        .content{
            padding: 30px 30px 0 30px;
        }
    }
}

@include md{
    .sidebar{
        background: $primary-3;
        margin: 0 -10px;
        width: auto;
        &:before{
            display: none;
        }
        .content{
            padding:10px;
        }
    }
}

@include md{
    .sidebar {
        .main-logo{
            flex-shrink: 0;
            margin: 0 auto;
            display: block;
        }
        .intro-img{
            display: none;
        }
    }
}
@include xs{
    .sidebar{
        margin: 0 -30px;
    }
}