:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #40af49;
  --secondary: #202420;
  --danger: #f44336;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-2 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-3 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-4 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 5;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.offset-1 {
  margin-left: 25%;
}

.offset-2 {
  margin-left: 50%;
}

.offset-3 {
  margin-left: 75%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-3 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 5;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 25%;
  }

  .offset-sm-2 {
    margin-left: 50%;
  }

  .offset-sm-3 {
    margin-left: 75%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-3 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 5;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 25%;
  }

  .offset-md-2 {
    margin-left: 50%;
  }

  .offset-md-3 {
    margin-left: 75%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-3 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 5;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 25%;
  }

  .offset-lg-2 {
    margin-left: 50%;
  }

  .offset-lg-3 {
    margin-left: 75%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-3 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 5;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 25%;
  }

  .offset-xl-2 {
    margin-left: 50%;
  }

  .offset-xl-3 {
    margin-left: 75%;
  }
}
.table {
  width: 100%;
  margin-bottom: 5px;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cae9cc;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9cd5a0;
}

.table-hover .table-primary:hover {
  background-color: #b8e2bb;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b8e2bb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c1c2c1;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #8b8d8b;
}

.table-hover .table-secondary:hover {
  background-color: #b4b5b4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b4b5b4;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f99d96;
}

.table-hover .table-danger:hover {
  background-color: #fbb3af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fbb3af;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #215b26;
  background-color: #d9efdb;
  border-color: #cae9cc;
}
.alert-primary hr {
  border-top-color: #b8e2bb;
}
.alert-primary .alert-link {
  color: #133616;
}

.alert-secondary {
  color: #111311;
  background-color: #d2d3d2;
  border-color: #c1c2c1;
}
.alert-secondary hr {
  border-top-color: #b4b5b4;
}
.alert-secondary .alert-link {
  color: black;
}

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0;
}

.font-mont {
  font-family: "Montserrat", sans-serif;
}

.font-raleway {
  font-family: "Raleway", sans-serif;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.tracking-tighter {
  letter-spacing: -0.05em;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.fz-12 {
  font-size: 12px;
}

.fz-14 {
  font-size: 14px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fz-22 {
  font-size: 22px;
}

.fz-32 {
  font-size: 32px;
}

@media (min-width: 576px) {
  .fz-sm-12 {
    font-size: 12px;
  }

  .fz-sm-14 {
    font-size: 14px;
  }

  .fz-sm-16 {
    font-size: 16px;
  }

  .fz-sm-18 {
    font-size: 18px;
  }

  .fz-sm-22 {
    font-size: 22px;
  }

  .fz-sm-32 {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .fz-md-12 {
    font-size: 12px;
  }

  .fz-md-14 {
    font-size: 14px;
  }

  .fz-md-16 {
    font-size: 16px;
  }

  .fz-md-18 {
    font-size: 18px;
  }

  .fz-md-22 {
    font-size: 22px;
  }

  .fz-md-32 {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .fz-lg-12 {
    font-size: 12px;
  }

  .fz-lg-14 {
    font-size: 14px;
  }

  .fz-lg-16 {
    font-size: 16px;
  }

  .fz-lg-18 {
    font-size: 18px;
  }

  .fz-lg-22 {
    font-size: 22px;
  }

  .fz-lg-32 {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .fz-xl-12 {
    font-size: 12px;
  }

  .fz-xl-14 {
    font-size: 14px;
  }

  .fz-xl-16 {
    font-size: 16px;
  }

  .fz-xl-18 {
    font-size: 18px;
  }

  .fz-xl-22 {
    font-size: 22px;
  }

  .fz-xl-32 {
    font-size: 32px;
  }
}
.img-responsive {
  max-width: 100%;
  margin: 0 auto;
}

.btn-1,
.btn-2 {
  min-width: 200px;
  text-align: center;
  font-size: 14px;
  padding: 14px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  display: inline-block;
  transition: all 0.2s;
  border: none;
}

.btn-1 {
  color: #fff;
  background: #40af49;
  box-shadow: 0px 3px 15px 0px rgba(32, 36, 32, 0.15);
}
.btn-1:hover {
  text-decoration: none;
  color: #202420;
  box-shadow: 0px 5px 18px 0px rgba(32, 36, 32, 0.25);
}

.btn-2 {
  background: #cfe6d0;
  color: #40af49;
}
.btn-2:hover {
  text-decoration: none;
  color: #202420;
}

.coupon-btn {
  width: 100%;
  min-width: inherit;
}

label {
  font-size: 16px;
  color: #202420;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 400;
}

.form-control {
  background: #f5faf5;
  border-radius: 5px;
  display: block;
  width: 100%;
  border: 1px solid #40af49;
  padding: 10px;
  color: #202420;
  outline: none;
  min-height: 50px;
}
.form-control:focus {
  box-shadow: 0px 0px 0px 1px #40af49;
}
.form-control.error {
  box-shadow: 0px 0px 0px 1px #f44336;
  background: rgba(244, 67, 54, 0.03);
  border-color: #f44336;
}
.form-control.calendar {
  padding-right: 40px;
  background-image: url(../images/svg/calendar-icon.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center;
}

.custom-select {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  border: 1px solid #40af49;
}
.custom-select:after {
  pointer-events: none;
  content: "";
  height: 7px;
  width: 12px;
  right: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 451.85 257.57'%3E%3Cpath d='M225.92 257.57a31.57 31.57 0 01-22.37-9.27L9.27 54A31.64 31.64 0 0154 9.27l171.9 171.91L397.83 9.27A31.64 31.64 0 01442.57 54L248.29 248.31a31.56 31.56 0 01-22.37 9.26z' fill='%23202420'/%3E%3C/svg%3E");
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}
.custom-select select {
  width: 120%;
  height: 50px;
  border: none !important;
}

.reset-input, .normal-checkbox, .normal-radio, .square-radio-box {
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.reset-input input[type=checkbox], .normal-checkbox input[type=checkbox], .normal-radio input[type=checkbox], .square-radio-box input[type=checkbox],
.reset-input input[type=radio],
.normal-checkbox input[type=radio],
.normal-radio input[type=radio],
.square-radio-box input[type=radio] {
  z-index: -99;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.radio-group {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.square-radio-box {
  margin: 0 15px 15px 0;
  display: inline-block;
}
.square-radio-box span {
  border: 2px solid #cfe6d0;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  text-align: center;
}
.square-radio-box input[type=radio]:checked ~ span {
  border-color: #40af49;
}

.normal-radio span {
  padding-left: 25px;
  position: relative;
  display: block;
}
.normal-radio span:before, .normal-radio span:after {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  left: 0;
  top: 3px;
  border: 2px solid #cfe6d0;
  content: "";
  border-radius: 50%;
}
.normal-radio span:before {
  border: none;
  width: 6px;
  height: 6px;
  left: 5px;
  top: 8px;
}
.normal-radio input[type=radio]:checked ~ span:before {
  background: #40af49;
}
.normal-radio input[type=radio]:checked ~ span:after {
  border-color: #40af49;
}

.normal-checkbox span {
  padding-left: 25px;
  position: relative;
  display: block;
}
.normal-checkbox span:after {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  left: 0;
  top: 3px;
  background-color: #e6f5e7;
  content: "";
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: center;
}
.normal-checkbox input[type=checkbox]:checked ~ span:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 376'%3E%3Cpath d='M504.5 7.5a25.59 25.59 0 00-36.2 0L161.59 314.21 43.7 196.31a25.6 25.6 0 00-36.2 36.21l136 136a25.61 25.61 0 0036.21 0L504.5 43.7a25.59 25.59 0 000-36.2z' fill='%2340af49' /%3E%3C/svg%3E");
}

.error-msg label {
  color: #f44336;
  font-size: 14px;
  padding-top: 5px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
}

.datepicker-container {
  background-color: #fff;
  direction: ltr;
  font-size: 12px;
  left: 0;
  line-height: 30px;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  touch-action: none;
  -webkit-touch-callout: none;
  user-select: none;
  width: 210px;
  z-index: -1;
}
.datepicker-container::before, .datepicker-container::after {
  border: 5px solid transparent;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.datepicker-dropdown {
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px #ccc;
  box-sizing: content-box;
  position: absolute;
  z-index: 1;
}
.datepicker-inline {
  position: static;
}
.datepicker-top-left, .datepicker-top-right {
  border-top-color: #39f;
}
.datepicker-top-left::before, .datepicker-top-left::after, .datepicker-top-right::before, .datepicker-top-right::after {
  border-top: 0;
  left: 10px;
  top: -5px;
}
.datepicker-top-left::before, .datepicker-top-right::before {
  border-bottom-color: #39f;
}
.datepicker-top-left::after, .datepicker-top-right::after {
  border-bottom-color: #fff;
  top: -4px;
}
.datepicker-bottom-left, .datepicker-bottom-right {
  border-bottom-color: #39f;
}
.datepicker-bottom-left::before, .datepicker-bottom-left::after, .datepicker-bottom-right::before, .datepicker-bottom-right::after {
  border-bottom: 0;
  bottom: -5px;
  left: 10px;
}
.datepicker-bottom-left::before, .datepicker-bottom-right::before {
  border-top-color: #39f;
}
.datepicker-bottom-left::after, .datepicker-bottom-right::after {
  border-top-color: #fff;
  bottom: -4px;
}
.datepicker-top-right::before, .datepicker-top-right::after, .datepicker-bottom-right::before, .datepicker-bottom-right::after {
  left: auto;
  right: 10px;
}
.datepicker-panel > ul {
  margin: 0;
  padding: 0;
  width: 102%;
}
.datepicker-panel > ul::before, .datepicker-panel > ul::after {
  content: " ";
  display: table;
}
.datepicker-panel > ul::after {
  clear: both;
}
.datepicker-panel > ul > li {
  background-color: #fff;
  cursor: pointer;
  float: left;
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 30px;
}
.datepicker-panel > ul > li:hover {
  background-color: #e5f2ff;
}
.datepicker-panel > ul > li.muted, .datepicker-panel > ul > li.muted:hover {
  color: #999;
}
.datepicker-panel > ul > li.highlighted {
  background-color: #e5f2ff;
}
.datepicker-panel > ul > li.highlighted:hover {
  background-color: #cce5ff;
}
.datepicker-panel > ul > li.picked, .datepicker-panel > ul > li.picked:hover {
  color: #39f;
}
.datepicker-panel > ul > li.disabled, .datepicker-panel > ul > li.disabled:hover {
  background-color: #fff;
  color: #ccc;
  cursor: default;
}
.datepicker-panel > ul > li.disabled.highlighted, .datepicker-panel > ul > li.disabled:hover.highlighted {
  background-color: #e5f2ff;
}
.datepicker-panel > ul > li[data-view="years prev"], .datepicker-panel > ul > li[data-view="year prev"], .datepicker-panel > ul > li[data-view="month prev"], .datepicker-panel > ul > li[data-view="years next"], .datepicker-panel > ul > li[data-view="year next"], .datepicker-panel > ul > li[data-view="month next"], .datepicker-panel > ul > li[data-view=next] {
  font-size: 18px;
}
.datepicker-panel > ul > li[data-view="years current"], .datepicker-panel > ul > li[data-view="year current"], .datepicker-panel > ul > li[data-view="month current"] {
  width: 150px;
}
.datepicker-panel > ul[data-view=years] > li, .datepicker-panel > ul[data-view=months] > li {
  height: 52.5px;
  line-height: 52.5px;
  width: 52.5px;
}
.datepicker-panel > ul[data-view=week] > li,
.datepicker-panel > ul[data-view=week] > li:hover {
  background-color: #fff;
  cursor: default;
}
.datepicker-hide {
  display: none;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  color: #40af49;
  background: rgba(255, 255, 255, 0.9);
  z-index: 99;
}
.loader span {
  position: fixed;
  top: 50%;
  width: 100%;
  text-align: center;
  margin-top: 70px;
}
.loader .preloader {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;
}

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
  }
}
.preloader div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.preloader div:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  width: 10%;
  height: 10%;
  background-color: #40af49;
  transform: translateX(-50%);
  border-radius: 50%;
}
.preloader div:nth-child(1) {
  transform: rotateZ(0deg);
  animation: rotateCircle1 2s infinite linear;
  z-index: 9;
}
.preloader div:nth-child(2) {
  transform: rotateZ(36deg);
  animation: rotateCircle2 2s infinite linear;
  z-index: 8;
}
.preloader div:nth-child(3) {
  transform: rotateZ(72deg);
  animation: rotateCircle3 2s infinite linear;
  z-index: 7;
}
.preloader div:nth-child(4) {
  transform: rotateZ(108deg);
  animation: rotateCircle4 2s infinite linear;
  z-index: 6;
}
.preloader div:nth-child(5) {
  transform: rotateZ(144deg);
  animation: rotateCircle5 2s infinite linear;
  z-index: 5;
}
.preloader div:nth-child(6) {
  transform: rotateZ(180deg);
  animation: rotateCircle6 2s infinite linear;
  z-index: 4;
}
.preloader div:nth-child(7) {
  transform: rotateZ(216deg);
  animation: rotateCircle7 2s infinite linear;
  z-index: 3;
}
.preloader div:nth-child(8) {
  transform: rotateZ(252deg);
  animation: rotateCircle8 2s infinite linear;
  z-index: 2;
}
.preloader div:nth-child(9) {
  transform: rotateZ(288deg);
  animation: rotateCircle9 2s infinite linear;
  z-index: 1;
}
.preloader div:nth-child(10) {
  transform: rotateZ(324deg);
  animation: rotateCircle10 2s infinite linear;
  z-index: 0;
}

@keyframes rotateCircle1 {
  0% {
    opacity: 0;
  }
  0% {
    opacity: 1;
    transform: rotateZ(36deg);
  }
  7% {
    transform: rotateZ(0deg);
  }
  57% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle2 {
  5% {
    opacity: 0;
  }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg);
  }
  12% {
    transform: rotateZ(-36deg);
  }
  62% {
    transform: rotateZ(-36deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle3 {
  10% {
    opacity: 0;
  }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg);
  }
  17% {
    transform: rotateZ(-72deg);
  }
  67% {
    transform: rotateZ(-72deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle4 {
  15% {
    opacity: 0;
  }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg);
  }
  22% {
    transform: rotateZ(-108deg);
  }
  72% {
    transform: rotateZ(-108deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle5 {
  20% {
    opacity: 0;
  }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg);
  }
  27% {
    transform: rotateZ(-144deg);
  }
  77% {
    transform: rotateZ(-144deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle6 {
  25% {
    opacity: 0;
  }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg);
  }
  32% {
    transform: rotateZ(-180deg);
  }
  82% {
    transform: rotateZ(-180deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle7 {
  30% {
    opacity: 0;
  }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg);
  }
  37% {
    transform: rotateZ(-216deg);
  }
  87% {
    transform: rotateZ(-216deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle8 {
  35% {
    opacity: 0;
  }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg);
  }
  42% {
    transform: rotateZ(-252deg);
  }
  92% {
    transform: rotateZ(-252deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle9 {
  40% {
    opacity: 0;
  }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg);
  }
  47% {
    transform: rotateZ(-288deg);
  }
  97% {
    transform: rotateZ(-288deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
@keyframes rotateCircle10 {
  45% {
    opacity: 0;
  }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg);
  }
  52% {
    transform: rotateZ(-324deg);
  }
  102% {
    transform: rotateZ(-324deg);
  }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1;
  }
}
.sq-field-wrapper {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 16px;
}

.sq-field {
  margin-bottom: 16px;
  width: 100%;
}

.sq-field:first-child {
  margin-left: 0;
}

.sq-field:last-child {
  margin-right: 0;
}

.sq-field--in-wrapper {
  flex-grow: 1;
  margin: 0 8px;
}

.sq-label {
  margin-bottom: 8px;
  text-transform: uppercase;
}

.sq-input {
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  transition: border-color 0.25s ease;
  width: 100%;
  height: 50px;
}

.sq-input--focus {
  background-color: #fbfdff;
}

.sq-input--error {
  background-color: #fbfdff;
}

.sq-button {
  color: #fff;
  padding: 16px;
  width: 100%;
}

.sq-button:active {
  color: #fff;
}

.sq-payment-form {
  max-width: 100%;
  padding: 20px 20px 5px;
  width: 100%;
}

.sq-label {
  color: #000000;
  font-size: 14px;
  font-family: "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.sq-input {
  background: #f5faf5;
  border-radius: 5px;
  display: block;
  width: 100%;
  border: 1px solid #40af49;
  color: #202420;
  outline: none;
  min-height: 50px;
}

.sq-input--focus {
  box-shadow: 0px 0px 0px 1px #40af49;
}

.sq-input--error {
  box-shadow: 0px 0px 0px 1px #f44336;
  background: rgba(244, 67, 54, 0.03);
  border-color: #f44336;
}

.sq-button {
  background: #4A90E2;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.sq-button:active {
  background: #4A90E2;
}

.sq-wallet-divider {
  margin: 24px 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.sq-wallet-divider:after, .sq-wallet-divider::after, .sq-wallet-divider:before, .sq-wallet-divider::before {
  background: #bbb;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 9px;
}

.sq-wallet-divider:after, .sq-wallet-divider::after {
  right: 65%;
}

.sq-wallet-divider:before, .sq-wallet-divider::before {
  left: 65%;
}

.sq-wallet-divider__text {
  color: #bbb;
  padding: 10px;
  text-transform: uppercase;
}

.sq-button:hover {
  cursor: pointer;
  background-color: #4281CB;
}

#error {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  color: red;
  font-weight: 500;
  text-align: center;
  opacity: 0.8;
}

body {
  overflow-x: hidden;
  font-family: "Raleway", sans-serif;
}
@media (max-width: 767px) {
  body {
    padding-bottom: 30px;
  }
}

@media (min-width: 300px) {
  .container {
    max-width: 1366px;
  }
}
.main-container {
  position: relative;
}
@media (max-width: 575px) {
  .main-container {
    padding: 0 30px;
  }
}

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 500px;
}

.main {
  flex: 0 0 calc(100% - 500px);
  max-width: calc(100% - 500px);
  margin-left: auto;
  padding: 30px 50px;
}
@media (max-width: 1199px) {
  .main {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .main {
    padding: 0;
  }
}

@media (max-width: 1366px) {
  .sidebar {
    width: 33.33%;
  }

  .main {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
}
@media (max-width: 991px) {
  .sidebar {
    width: 100%;
    position: relative;
  }

  .main {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.sidebar .main-logo {
  max-width: 140px;
  display: none;
}
.sidebar:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -100000px;
  z-index: 0;
  background-color: #e6f5e7;
  content: "";
}
.sidebar .content {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 50px 0 0 0;
}
.sidebar .intro-img {
  margin-top: auto;
}

@media (max-width: 1199px) {
  .sidebar .content {
    padding: 30px 30px 0 30px;
  }
}
@media (max-width: 991px) {
  .sidebar {
    background: #e6f5e7;
    margin: 0 -10px;
    width: auto;
  }
  .sidebar:before {
    display: none;
  }
  .sidebar .content {
    padding: 10px;
  }
}
@media (max-width: 991px) {
  .sidebar .main-logo {
    flex-shrink: 0;
    margin: 0 auto;
    display: block;
  }
  .sidebar .intro-img {
    display: none;
  }
}
@media (max-width: 575px) {
  .sidebar {
    margin: 0 -30px;
  }
}
.plan-boxes {
  display: flex;
  align-items: stretch;
  padding-top: 30px;
  justify-content: center;
}
.plan-boxes .box {
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid #e6f5e7;
  width: 100%;
  padding: 20px;
  max-width: 350px;
}
.plan-boxes .box:before {
  margin: -20px -20px 20px -20px;
  display: block;
  height: 6px;
  background: #40af49;
  content: "";
}
.plan-boxes .lite-plan {
  margin-top: 20px;
}
.plan-boxes .pro-plan {
  border: none;
  margin-left: -10px;
  z-index: 2;
  box-shadow: 0px 3px 15px 0px rgba(32, 36, 32, 0.15);
}
.plan-boxes .plan-title {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 14px;
  color: #202420;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.plan-boxes .plan-price {
  margin-bottom: 30px;
}
.plan-boxes .plan-price .price {
  color: #40af49;
  font-weight: 300;
  font-size: 32px;
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: center;
  line-height: 1;
  margin-bottom: 10px;
}
.plan-boxes .plan-price .price span {
  font-size: 18px;
  font-weight: 600;
  color: #202420;
}
.plan-boxes .plan-price .info {
  text-align: center;
  color: #202420;
  opacity: 0.7;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: block;
}
.plan-boxes .not-include,
.plan-boxes .plan-detail {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.plan-boxes .not-include > li,
.plan-boxes .plan-detail > li {
  padding-left: 30px;
  background-image: url(../images/svg/check-icon.svg);
  background-repeat: no-repeat;
  background-position: left 5px;
  background-size: 13px;
}
.plan-boxes .not-include > li > ul,
.plan-boxes .plan-detail > li > ul {
  padding-left: 10px;
}
.plan-boxes .not-include > li > ul > li,
.plan-boxes .plan-detail > li > ul > li {
  list-style-type: disc;
}
.plan-boxes .not-include {
  list-style-type: none !important;
  margin: 0;
  padding: 0 !important;
  margin-left: -10px;
}
.plan-boxes .not-include > li {
  padding-left: 20px;
  list-style-type: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51.976 51.976'%3E%3Cpath d='M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 01-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 11-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 112.828-2.828l7.425 7.425 7.071-7.071a2 2 0 112.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 010 2.828z' fill='red' /%3E%3C/svg%3E");
}
.plan-boxes .plan-price-box {
  font-weight: 600;
  padding-top: 128px;
}

@media (max-width: 575px) {
  .plan-boxes {
    flex-direction: column;
  }
  .plan-boxes .box {
    max-width: inherit;
    margin-bottom: 20px;
  }
  .plan-boxes .lite-plan {
    margin-top: 0;
  }
  .plan-boxes .pro-plan {
    margin-left: 0;
  }
  .plan-boxes .plan-price-box {
    padding-top: 0;
  }
}
.progress-bar {
  height: 5px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}
.progress-bar .progress {
  height: 5px;
  border-radius: 10px;
}

.square-radio-box {
  display: block;
  width: calc(33.33% - 15px);
}
@media (max-width: 767px) {
  .square-radio-box {
    width: calc(50% - 7px);
  }
  .square-radio-box:nth-child(odd) {
    margin-right: 0;
  }
  .square-radio-box:nth-child(even) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (max-width: 575px) {
  .square-radio-box {
    width: 100%;
  }
  .square-radio-box span {
    text-align: left;
  }
}

.mobile-target {
  display: none;
  width: 100%;
}

@media (max-width: 575px) {
  .step-1-option-3-mobile {
    display: none !important;
  }

  .mobile-target {
    display: block;
  }

  .step-1-steps {
    padding-bottom: 40px;
  }
}
.info-link img {
  width: 16px;
}

.hide-number .form-group label span {
  display: none;
}

.warning-box {
  position: relative;
  border-radius: 7px;
  padding-left: 60px !important;
}
.warning-box .warning-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 24px;
}
.warning-box ul {
  padding-left: 17px;
  margin: 0;
}

@media (max-width: 575px) {
  .warning-box {
    padding-left: 30px !important;
  }
  .warning-box .warning-icon {
    position: absolute;
    top: 22px;
    left: 5px;
    width: 16px;
  }
  .warning-box ul {
    padding: 0;
  }
}
.summary-container {
  border-radius: 7px;
}
@media (max-width: 991px) {
  .summary-container {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    min-height: 0.01%;
    overflow-x: auto;
  }
  .summary-container tr {
    display: block;
  }
  .summary-container tr.hr-row, .summary-container tr.hr-row td {
    display: block;
    width: 100%;
  }
  .summary-container tr.footer-row {
    display: flex;
    justify-content: space-between;
  }
}

.summary-table {
  width: 100%;
  max-width: 100%;
}
.summary-table hr {
  margin: 2px 0;
  border: none;
  background: none;
  display: block;
  border-top: 1px solid #cfe6d0;
  width: 100%;
}
.summary-table td, .summary-table th {
  vertical-align: top;
  padding: 5px 10px;
}
@media (max-width: 991px) {
  .summary-table .tracking-wider {
    display: none;
  }
  .summary-table .summary-data-content td {
    display: flex;
    text-align: right;
    justify-content: space-between;
  }
  .summary-table .summary-data-content td:before {
    content: attr(data-title);
    font-weight: 700;
    width: 100px;
    padding-right: 15px;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 1;
    word-break: break-word;
  }
  .summary-table .summary-data-content td span {
    flex-grow: 1;
    display: block;
  }
}

.sccess-image {
  max-width: 400px;
}

.reference-number {
  max-width: 150px;
}

@media (max-width: 575px) {
  .navigator-buttons .btn-1, .navigator-buttons .btn-2 {
    flex: 0 0 45%;
    max-width: 45%;
    min-width: inherit;
  }
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.bg-primary {
  background-color: #40af49 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #328a39 !important;
}

.bg-secondary {
  background-color: #202420 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #080908 !important;
}

.bg-primary-1 {
  background-color: #6b996f !important;
}

a.bg-primary-1:hover, a.bg-primary-1:focus,
button.bg-primary-1:hover,
button.bg-primary-1:focus {
  background-color: #557c59 !important;
}

.bg-primary-2 {
  background-color: #cfe6d0 !important;
}

a.bg-primary-2:hover, a.bg-primary-2:focus,
button.bg-primary-2:hover,
button.bg-primary-2:focus {
  background-color: #add5af !important;
}

.bg-primary-3 {
  background-color: #e6f5e7 !important;
}

a.bg-primary-3:hover, a.bg-primary-3:focus,
button.bg-primary-3:hover,
button.bg-primary-3:focus {
  background-color: #c2e6c4 !important;
}

.bg-primary-4 {
  background-color: #f5faf5 !important;
}

a.bg-primary-4:hover, a.bg-primary-4:focus,
button.bg-primary-4:hover,
button.bg-primary-4:focus {
  background-color: #d3e9d3 !important;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-table {
    display: table;
  }

  .d-sm-table-row {
    display: table-row;
  }

  .d-sm-table-cell {
    display: table-cell;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-table {
    display: table;
  }

  .d-md-table-row {
    display: table-row;
  }

  .d-md-table-cell {
    display: table-cell;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-table {
    display: table;
  }

  .d-lg-table-row {
    display: table-row;
  }

  .d-lg-table-cell {
    display: table-cell;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-table {
    display: table;
  }

  .d-xl-table-row {
    display: table-row;
  }

  .d-xl-table-cell {
    display: table-cell;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }
}
@media print {
  .d-print-none {
    display: none;
  }

  .d-print-inline {
    display: inline;
  }

  .d-print-inline-block {
    display: inline-block;
  }

  .d-print-block {
    display: block;
  }

  .d-print-table {
    display: table;
  }

  .d-print-table-row {
    display: table-row;
  }

  .d-print-table-cell {
    display: table-cell;
  }

  .d-print-flex {
    display: flex;
  }

  .d-print-inline-flex {
    display: inline-flex;
  }
}
.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-wrap {
    flex-wrap: wrap;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-sm-fill {
    flex: 1 1 auto;
  }

  .flex-sm-grow-0 {
    flex-grow: 0;
  }

  .flex-sm-grow-1 {
    flex-grow: 1;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .justify-content-sm-center {
    justify-content: center;
  }

  .justify-content-sm-between {
    justify-content: space-between;
  }

  .justify-content-sm-around {
    justify-content: space-around;
  }

  .align-items-sm-start {
    align-items: flex-start;
  }

  .align-items-sm-end {
    align-items: flex-end;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .align-items-sm-baseline {
    align-items: baseline;
  }

  .align-items-sm-stretch {
    align-items: stretch;
  }

  .align-content-sm-start {
    align-content: flex-start;
  }

  .align-content-sm-end {
    align-content: flex-end;
  }

  .align-content-sm-center {
    align-content: center;
  }

  .align-content-sm-between {
    align-content: space-between;
  }

  .align-content-sm-around {
    align-content: space-around;
  }

  .align-content-sm-stretch {
    align-content: stretch;
  }

  .align-self-sm-auto {
    align-self: auto;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .align-self-sm-end {
    align-self: flex-end;
  }

  .align-self-sm-center {
    align-self: center;
  }

  .align-self-sm-baseline {
    align-self: baseline;
  }

  .align-self-sm-stretch {
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-column {
    flex-direction: column;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-md-wrap {
    flex-wrap: wrap;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-md-fill {
    flex: 1 1 auto;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .flex-md-grow-1 {
    flex-grow: 1;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-md-start {
    justify-content: flex-start;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .justify-content-md-between {
    justify-content: space-between;
  }

  .justify-content-md-around {
    justify-content: space-around;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-end {
    align-items: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .align-items-md-baseline {
    align-items: baseline;
  }

  .align-items-md-stretch {
    align-items: stretch;
  }

  .align-content-md-start {
    align-content: flex-start;
  }

  .align-content-md-end {
    align-content: flex-end;
  }

  .align-content-md-center {
    align-content: center;
  }

  .align-content-md-between {
    align-content: space-between;
  }

  .align-content-md-around {
    align-content: space-around;
  }

  .align-content-md-stretch {
    align-content: stretch;
  }

  .align-self-md-auto {
    align-self: auto;
  }

  .align-self-md-start {
    align-self: flex-start;
  }

  .align-self-md-end {
    align-self: flex-end;
  }

  .align-self-md-center {
    align-self: center;
  }

  .align-self-md-baseline {
    align-self: baseline;
  }

  .align-self-md-stretch {
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-lg-wrap {
    flex-wrap: wrap;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-lg-fill {
    flex: 1 1 auto;
  }

  .flex-lg-grow-0 {
    flex-grow: 0;
  }

  .flex-lg-grow-1 {
    flex-grow: 1;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-lg-start {
    justify-content: flex-start;
  }

  .justify-content-lg-end {
    justify-content: flex-end;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .justify-content-lg-between {
    justify-content: space-between;
  }

  .justify-content-lg-around {
    justify-content: space-around;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .align-items-lg-end {
    align-items: flex-end;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .align-items-lg-baseline {
    align-items: baseline;
  }

  .align-items-lg-stretch {
    align-items: stretch;
  }

  .align-content-lg-start {
    align-content: flex-start;
  }

  .align-content-lg-end {
    align-content: flex-end;
  }

  .align-content-lg-center {
    align-content: center;
  }

  .align-content-lg-between {
    align-content: space-between;
  }

  .align-content-lg-around {
    align-content: space-around;
  }

  .align-content-lg-stretch {
    align-content: stretch;
  }

  .align-self-lg-auto {
    align-self: auto;
  }

  .align-self-lg-start {
    align-self: flex-start;
  }

  .align-self-lg-end {
    align-self: flex-end;
  }

  .align-self-lg-center {
    align-self: center;
  }

  .align-self-lg-baseline {
    align-self: baseline;
  }

  .align-self-lg-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xl-wrap {
    flex-wrap: wrap;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .flex-xl-fill {
    flex: 1 1 auto;
  }

  .flex-xl-grow-0 {
    flex-grow: 0;
  }

  .flex-xl-grow-1 {
    flex-grow: 1;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }

  .justify-content-xl-start {
    justify-content: flex-start;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .justify-content-xl-around {
    justify-content: space-around;
  }

  .align-items-xl-start {
    align-items: flex-start;
  }

  .align-items-xl-end {
    align-items: flex-end;
  }

  .align-items-xl-center {
    align-items: center;
  }

  .align-items-xl-baseline {
    align-items: baseline;
  }

  .align-items-xl-stretch {
    align-items: stretch;
  }

  .align-content-xl-start {
    align-content: flex-start;
  }

  .align-content-xl-end {
    align-content: flex-end;
  }

  .align-content-xl-center {
    align-content: center;
  }

  .align-content-xl-between {
    align-content: space-between;
  }

  .align-content-xl-around {
    align-content: space-around;
  }

  .align-content-xl-stretch {
    align-content: stretch;
  }

  .align-self-xl-auto {
    align-self: auto;
  }

  .align-self-xl-start {
    align-self: flex-start;
  }

  .align-self-xl-end {
    align-self: flex-end;
  }

  .align-self-xl-center {
    align-self: center;
  }

  .align-self-xl-baseline {
    align-self: baseline;
  }

  .align-self-xl-stretch {
    align-self: stretch;
  }
}
.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.mb-0,
.my-0 {
  margin-bottom: 0;
}

.ml-0,
.mx-0 {
  margin-left: 0;
}

.m-1 {
  margin: 5px;
}

.mt-1,
.my-1 {
  margin-top: 5px;
}

.mr-1,
.mx-1 {
  margin-right: 5px;
}

.mb-1,
.my-1 {
  margin-bottom: 5px;
}

.ml-1,
.mx-1 {
  margin-left: 5px;
}

.m-2 {
  margin: 10px;
}

.mt-2,
.my-2 {
  margin-top: 10px;
}

.mr-2,
.mx-2 {
  margin-right: 10px;
}

.mb-2,
.my-2 {
  margin-bottom: 10px;
}

.ml-2,
.mx-2 {
  margin-left: 10px;
}

.m-3 {
  margin: 20px;
}

.mt-3,
.my-3 {
  margin-top: 20px;
}

.mr-3,
.mx-3 {
  margin-right: 20px;
}

.mb-3,
.my-3 {
  margin-bottom: 20px;
}

.ml-3,
.mx-3 {
  margin-left: 20px;
}

.m-4 {
  margin: 30px;
}

.mt-4,
.my-4 {
  margin-top: 30px;
}

.mr-4,
.mx-4 {
  margin-right: 30px;
}

.mb-4,
.my-4 {
  margin-bottom: 30px;
}

.ml-4,
.mx-4 {
  margin-left: 30px;
}

.m-5 {
  margin: 40px;
}

.mt-5,
.my-5 {
  margin-top: 40px;
}

.mr-5,
.mx-5 {
  margin-right: 40px;
}

.mb-5,
.my-5 {
  margin-bottom: 40px;
}

.ml-5,
.mx-5 {
  margin-left: 40px;
}

.p-0 {
  padding: 0;
}

.pt-0,
.py-0 {
  padding-top: 0;
}

.pr-0,
.px-0 {
  padding-right: 0;
}

.pb-0,
.py-0 {
  padding-bottom: 0;
}

.pl-0,
.px-0 {
  padding-left: 0;
}

.p-1 {
  padding: 5px;
}

.pt-1,
.py-1 {
  padding-top: 5px;
}

.pr-1,
.px-1 {
  padding-right: 5px;
}

.pb-1,
.py-1 {
  padding-bottom: 5px;
}

.pl-1,
.px-1 {
  padding-left: 5px;
}

.p-2 {
  padding: 10px;
}

.pt-2,
.py-2 {
  padding-top: 10px;
}

.pr-2,
.px-2 {
  padding-right: 10px;
}

.pb-2,
.py-2 {
  padding-bottom: 10px;
}

.pl-2,
.px-2 {
  padding-left: 10px;
}

.p-3 {
  padding: 20px;
}

.pt-3,
.py-3 {
  padding-top: 20px;
}

.pr-3,
.px-3 {
  padding-right: 20px;
}

.pb-3,
.py-3 {
  padding-bottom: 20px;
}

.pl-3,
.px-3 {
  padding-left: 20px;
}

.p-4 {
  padding: 30px;
}

.pt-4,
.py-4 {
  padding-top: 30px;
}

.pr-4,
.px-4 {
  padding-right: 30px;
}

.pb-4,
.py-4 {
  padding-bottom: 30px;
}

.pl-4,
.px-4 {
  padding-left: 30px;
}

.p-5 {
  padding: 40px;
}

.pt-5,
.py-5 {
  padding-top: 40px;
}

.pr-5,
.px-5 {
  padding-right: 40px;
}

.pb-5,
.py-5 {
  padding-bottom: 40px;
}

.pl-5,
.px-5 {
  padding-left: 40px;
}

.m-n1 {
  margin: -5px;
}

.mt-n1,
.my-n1 {
  margin-top: -5px;
}

.mr-n1,
.mx-n1 {
  margin-right: -5px;
}

.mb-n1,
.my-n1 {
  margin-bottom: -5px;
}

.ml-n1,
.mx-n1 {
  margin-left: -5px;
}

.m-n2 {
  margin: -10px;
}

.mt-n2,
.my-n2 {
  margin-top: -10px;
}

.mr-n2,
.mx-n2 {
  margin-right: -10px;
}

.mb-n2,
.my-n2 {
  margin-bottom: -10px;
}

.ml-n2,
.mx-n2 {
  margin-left: -10px;
}

.m-n3 {
  margin: -20px;
}

.mt-n3,
.my-n3 {
  margin-top: -20px;
}

.mr-n3,
.mx-n3 {
  margin-right: -20px;
}

.mb-n3,
.my-n3 {
  margin-bottom: -20px;
}

.ml-n3,
.mx-n3 {
  margin-left: -20px;
}

.m-n4 {
  margin: -30px;
}

.mt-n4,
.my-n4 {
  margin-top: -30px;
}

.mr-n4,
.mx-n4 {
  margin-right: -30px;
}

.mb-n4,
.my-n4 {
  margin-bottom: -30px;
}

.ml-n4,
.mx-n4 {
  margin-left: -30px;
}

.m-n5 {
  margin: -40px;
}

.mt-n5,
.my-n5 {
  margin-top: -40px;
}

.mr-n5,
.mx-n5 {
  margin-right: -40px;
}

.mb-n5,
.my-n5 {
  margin-bottom: -40px;
}

.ml-n5,
.mx-n5 {
  margin-left: -40px;
}

.m-auto {
  margin: auto;
}

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0;
  }

  .m-sm-1 {
    margin: 5px;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 5px;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 5px;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 5px;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 5px;
  }

  .m-sm-2 {
    margin: 10px;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 10px;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 10px;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 10px;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 10px;
  }

  .m-sm-3 {
    margin: 20px;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 20px;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 20px;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 20px;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 20px;
  }

  .m-sm-4 {
    margin: 30px;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 30px;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 30px;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 30px;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 30px;
  }

  .m-sm-5 {
    margin: 40px;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 40px;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 40px;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 40px;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 40px;
  }

  .p-sm-0 {
    padding: 0;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0;
  }

  .p-sm-1 {
    padding: 5px;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 5px;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 5px;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 5px;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 5px;
  }

  .p-sm-2 {
    padding: 10px;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 10px;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 10px;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 10px;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 10px;
  }

  .p-sm-3 {
    padding: 20px;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 20px;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 20px;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 20px;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 20px;
  }

  .p-sm-4 {
    padding: 30px;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 30px;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 30px;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 30px;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 30px;
  }

  .p-sm-5 {
    padding: 40px;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 40px;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 40px;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 40px;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 40px;
  }

  .m-sm-n1 {
    margin: -5px;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -5px;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -5px;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -5px;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -5px;
  }

  .m-sm-n2 {
    margin: -10px;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -10px;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -10px;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -10px;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -10px;
  }

  .m-sm-n3 {
    margin: -20px;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -20px;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -20px;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -20px;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -20px;
  }

  .m-sm-n4 {
    margin: -30px;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -30px;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -30px;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -30px;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -30px;
  }

  .m-sm-n5 {
    margin: -40px;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -40px;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -40px;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -40px;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -40px;
  }

  .m-sm-auto {
    margin: auto;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0;
  }

  .m-md-1 {
    margin: 5px;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 5px;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 5px;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 5px;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 5px;
  }

  .m-md-2 {
    margin: 10px;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 10px;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 10px;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 10px;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 10px;
  }

  .m-md-3 {
    margin: 20px;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 20px;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 20px;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 20px;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 20px;
  }

  .m-md-4 {
    margin: 30px;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 30px;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 30px;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 30px;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 30px;
  }

  .m-md-5 {
    margin: 40px;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 40px;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 40px;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 40px;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 40px;
  }

  .p-md-0 {
    padding: 0;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0;
  }

  .p-md-1 {
    padding: 5px;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 5px;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 5px;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 5px;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 5px;
  }

  .p-md-2 {
    padding: 10px;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 10px;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 10px;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 10px;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 10px;
  }

  .p-md-3 {
    padding: 20px;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 20px;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 20px;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 20px;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 20px;
  }

  .p-md-4 {
    padding: 30px;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 30px;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 30px;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 30px;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 30px;
  }

  .p-md-5 {
    padding: 40px;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 40px;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 40px;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 40px;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 40px;
  }

  .m-md-n1 {
    margin: -5px;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -5px;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -5px;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -5px;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -5px;
  }

  .m-md-n2 {
    margin: -10px;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -10px;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -10px;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -10px;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -10px;
  }

  .m-md-n3 {
    margin: -20px;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -20px;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -20px;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -20px;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -20px;
  }

  .m-md-n4 {
    margin: -30px;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -30px;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -30px;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -30px;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -30px;
  }

  .m-md-n5 {
    margin: -40px;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -40px;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -40px;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -40px;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -40px;
  }

  .m-md-auto {
    margin: auto;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0;
  }

  .m-lg-1 {
    margin: 5px;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 5px;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 5px;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 5px;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 5px;
  }

  .m-lg-2 {
    margin: 10px;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 10px;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 10px;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 10px;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 10px;
  }

  .m-lg-3 {
    margin: 20px;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 20px;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 20px;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 20px;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 20px;
  }

  .m-lg-4 {
    margin: 30px;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 30px;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 30px;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 30px;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 30px;
  }

  .m-lg-5 {
    margin: 40px;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 40px;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 40px;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 40px;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 40px;
  }

  .p-lg-0 {
    padding: 0;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0;
  }

  .p-lg-1 {
    padding: 5px;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 5px;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 5px;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 5px;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 5px;
  }

  .p-lg-2 {
    padding: 10px;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 10px;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 10px;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 10px;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 10px;
  }

  .p-lg-3 {
    padding: 20px;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 20px;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 20px;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 20px;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 20px;
  }

  .p-lg-4 {
    padding: 30px;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 30px;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 30px;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 30px;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 30px;
  }

  .p-lg-5 {
    padding: 40px;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 40px;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 40px;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 40px;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 40px;
  }

  .m-lg-n1 {
    margin: -5px;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -5px;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -5px;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -5px;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -5px;
  }

  .m-lg-n2 {
    margin: -10px;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -10px;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -10px;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -10px;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -10px;
  }

  .m-lg-n3 {
    margin: -20px;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -20px;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -20px;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -20px;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -20px;
  }

  .m-lg-n4 {
    margin: -30px;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -30px;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -30px;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -30px;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -30px;
  }

  .m-lg-n5 {
    margin: -40px;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -40px;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -40px;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -40px;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -40px;
  }

  .m-lg-auto {
    margin: auto;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0;
  }

  .m-xl-1 {
    margin: 5px;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 5px;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 5px;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 5px;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 5px;
  }

  .m-xl-2 {
    margin: 10px;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 10px;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 10px;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 10px;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 10px;
  }

  .m-xl-3 {
    margin: 20px;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 20px;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 20px;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 20px;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 20px;
  }

  .m-xl-4 {
    margin: 30px;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 30px;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 30px;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 30px;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 30px;
  }

  .m-xl-5 {
    margin: 40px;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 40px;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 40px;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 40px;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 40px;
  }

  .p-xl-0 {
    padding: 0;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0;
  }

  .p-xl-1 {
    padding: 5px;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 5px;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 5px;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 5px;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 5px;
  }

  .p-xl-2 {
    padding: 10px;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 10px;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 10px;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 10px;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 10px;
  }

  .p-xl-3 {
    padding: 20px;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 20px;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 20px;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 20px;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 20px;
  }

  .p-xl-4 {
    padding: 30px;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 30px;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 30px;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 30px;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 30px;
  }

  .p-xl-5 {
    padding: 40px;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 40px;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 40px;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 40px;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 40px;
  }

  .m-xl-n1 {
    margin: -5px;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -5px;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -5px;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -5px;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -5px;
  }

  .m-xl-n2 {
    margin: -10px;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -10px;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -10px;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -10px;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -10px;
  }

  .m-xl-n3 {
    margin: -20px;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -20px;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -20px;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -20px;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -20px;
  }

  .m-xl-n4 {
    margin: -30px;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -30px;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -30px;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -30px;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -30px;
  }

  .m-xl-n5 {
    margin: -40px;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -40px;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -40px;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -40px;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -40px;
  }

  .m-xl-auto {
    margin: auto;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }

  .text-xl-right {
    text-align: right;
  }

  .text-xl-center {
    text-align: center;
  }
}
.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: #40af49 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #2c7732 !important;
}

.text-secondary {
  color: #202420 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-secondary-1 {
  color: #5b665b !important;
}

a.text-secondary-1:hover, a.text-secondary-1:focus {
  color: #373e37 !important;
}

.text-primary-1 {
  color: #6b996f !important;
}

a.text-primary-1:hover, a.text-primary-1:focus {
  color: #4b6d4e !important;
}

.text-danger {
  color: #f44336 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #d2190b !important;
}

.text-body {
  color: #212529;
}

.text-muted {
  color: #6c757d;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-break {
  word-break: break-word;
  overflow-wrap: break-word;
}

.text-reset {
  color: inherit;
}