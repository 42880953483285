.info-link{
    img{
        width: 16px;
    }
}

// Sim
.hide-number{
    .form-group{
        label{
            span{
                display: none;
            }
        }
    }
}