.summary-container{
    border-radius: $brr-2;
    @include md{
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        min-height: .01%;
        overflow-x: auto;
        tr{
            display: block;
            &.hr-row,
            &.hr-row td{
                display: block;
                width: 100%;
            }
            &.footer-row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.summary-table{
    width: 100%;
    max-width: 100%;
    hr{
        margin: 2px 0;
        border: none;
        background: none;
        display: block;
        border-top:1px solid $primary-2;
        width: 100%;
    }
    td,th{
        vertical-align: top;
        padding: 5px 10px;
    }
    @include md{
        .tracking-wider {
            display: none;
        }
        .summary-data-content{
            td {
                display: flex;
                text-align: right;
                justify-content: space-between;
                &:before{
                    content: attr(data-title);
                    font-weight: 700;
                    width: 100px;
                    padding-right: 15px;
                    flex-shrink: 0;
                    font-size: 12px;
                    line-height: 1;
                    word-break: break-word;
                }
                span{
                    flex-grow: 1;
                    display: block;
                }
            }
        }
    }
}